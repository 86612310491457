import { TypeAny } from "@brightinsight/bi-web-core";
import { Feature } from "modules/common/@types/common";
// Interface for the menu item
interface MenuItem {
  key: string;
  path: string;
  label: string;
  iconSource: string;
}

interface RouteConfig {
  path: string;
  component: React.ComponentType;
  actionName: string;
  displayName: string;
  isMenu: boolean;
}

export const getRoutes = async (portalContextModules: Feature[]) => {
  const module: TypeAny = await import("@brightinsight/bi-web-common-modules");
  const allAuthRoutes: RouteConfig[] = [];
  const allMenus: MenuItem[] = [];

  if (module) {
    await Promise.all(
      portalContextModules.map(async (featureObj: Feature) => {
        const { name: featureName } = featureObj;
        if (module[featureName]) {
          // Get the routes object from the module
          const routesObj = module[featureName](featureObj);

          // Add the routes to the allAuthRoutes array
          allAuthRoutes.push(...routesObj.configureObj.routes);

          // If there are menu options, add them to the allMenus array
          if (routesObj.configureObj.menuOptions) {
            allMenus.push(...routesObj.configureObj.menuOptions);
          }
        }
      })
    );
  }

  // Return the collected routes and menus
  return { allAuthRoutes, allMenus };
};
