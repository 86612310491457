import Cookies from "js-cookie";
import {
  ModuleAction,
  ModuleState,
  AppConfigContextType,
  GlobalConfigurationValue,
} from "@brightinsight/bi-web-core";
import { AppConstants } from "./../constants/AppConstants";
import { GetLoggedInUserResponse } from "@brightinsight/core-services";

// Reducer function to handle state changes
export const AppConfigReducer = (
  state: ModuleState<AppConfigContextType>,
  { type, payload }: ModuleAction<AppConfigContextType>
) => {
  switch (type) {
    case "setAppConfigState":
      return { data: { ...state.data, ...payload } };
    case "setUserProfileInfo":
      localStorage.setItem("userDetails", JSON.stringify(payload?.userDetails));
      return { data: { ...state.data, userDetails: payload?.userDetails } };
    default:
      return state;
  }
};

// Initial state for the app configuration
export const initialState: AppConfigContextType = {
  isLoggedIn: !!Cookies.get(AppConstants.ACCESS_TOKEN),
  globalConfigsLoaded: false,
  renewingSession: false,
  genericConfigurations: {} as GlobalConfigurationValue,
  appContext: AppConstants.DEFAULT_APP_CONTEXT,
  applicationFeatures: {},
  userDetails: JSON.parse(
    localStorage.getItem("userDetails") || "{}"
  ) as GetLoggedInUserResponse,
  languageConfigs: {
    organizationId: "",
    backgroundColor: "",
    supportPhone: "",
  },
  landingPageValueProps: {},
};
