import React from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "react-jss";
import { initLocalization } from "./localization/i18n.init";
import {
  cacheLayer,
  ParamMap,
} from "@brightinsight/bi-web-core";

import { getSystemSettings } from "@brightinsight/bi-web-common-modules";
import { App } from "./App";
import { GlobalStyles } from "../src/common/@types/common.interface";
import { configurationKeys } from "./common/config";
import { initDataDogRum } from "./datadogRumConfiguration";
import { isRtl } from "./utils/common";

import {
  getQueryParams,
} from "./utils/common";

import { COUNTRY_QUERY_PARAM, URL_SEARCH_PARAMS } from "./constants";


// Get the root element where the app will be rendered
const rootElement = document.getElementById("care-team-portal");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootElement!);
const fetchStylesAndCreateTheme = async (): Promise<object> => {

  const params: ParamMap<string> = getQueryParams(URL_SEARCH_PARAMS);
  const country = params[COUNTRY_QUERY_PARAM];
  let globalStylesObj: GlobalStyles;  
  if (country) {
    globalStylesObj = await getSystemSettings({
      category: `${configurationKeys.webStyleConfigurationCategory}`,
      key: configurationKeys.webStyleConfigurationKey,
      organization: cacheLayer.getFromCache("currentOrganizationId"),
      fromCacheIfAvailable: true,
      isPublic: true,
    });
  } else {
    globalStylesObj = await getSystemSettings({
      category: `${configurationKeys.webStyleConfigurationCategory}`,
      key: configurationKeys.webStyleConfigurationKey,
      fromCacheIfAvailable: true,
      isPublic: true,
    });
  
  }
  // Extract styles from the fetched settings
  const { value: styles } = globalStylesObj;

  // Create a theme object with the extracted styles
  const theme = styles
    ? {
        color: { ...styles.color },
        theme: { ...styles.theme },
      }
    : {};

  return theme;
};

const renderAppWithTheme = (theme: object) => {
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <div dir={isRtl(navigator.language) ? "rtl" : "ltr"}>
          <App />
        </div>
      </ThemeProvider>
    </React.StrictMode>
  );
};

const renderErrorMessage = () => {
  root.render(
    <div>
      <h1>Something went wrong!</h1>
    </div>
  );
};

// Function to initialize the app
const renderApp = async () => {
  // Initialize localization
  const localizationInitiated = await initLocalization();

  if (localizationInitiated) {
    // Initialize Datadog RUM for monitoring
    initDataDogRum();
    // TODO: Change directly to system settings key
    // Fetch global styles from system settings
    const theme = await fetchStylesAndCreateTheme();

    if (localizationInitiated) {
      // Render the app with the theme provider
      renderAppWithTheme(theme);
    } else {
      renderErrorMessage();
    }
  }
};

// Call the function to initialize the app
renderApp();
