import React, { ReactNode } from "react";
import { AppConfigProvider } from "@brightinsight/bi-web-core";
import { AppConfigReducer, initialState } from "./AppConfigUtils";

// Provider component to wrap the application with the app configuration context
const GlobalAppConfigProvider = ({ children }: { children: ReactNode }) => {
  return (
    <AppConfigProvider initialData={initialState} reducer={AppConfigReducer}>
      {children}
    </AppConfigProvider>
  );
};

export default GlobalAppConfigProvider;
