import { initReactI18next } from "react-i18next";
import i18n, { BackendModule, InitOptions, ReadCallback } from "i18next";
// optionally we could use http backend plugin...
//import Backend from 'i18next-http-backend'

import {
  StorageManager,
  InjectionManager,
  cacheLayer,
  ParamMap,
} from "@brightinsight/bi-web-core";
import { getSystemSettings } from "@brightinsight/bi-web-common-modules";
import API_BASE_CONFIGS from "../common/config";

import {
  EnvironmentManager as EnvironmentManagerCore,
  InjectionManager as InjectionManagerCore,
  NetworkManager,
  AuthenticationService,
} from "@brightinsight/core-services";

AuthenticationService.setClientId("oidc_pkce");

import { configurationKeys } from "./../common/config";
import {
  AvailableLocale,
  GlobalConfigurationsProperties,
} from "common/@types/common.interface";
import { AppConstants } from "../constants/AppConstants";
import { removeAuthenticationCookies } from "../utils/authUtil";
import {
  getQueryParams,
  makeTheNavigatorLangAsDefaultIfExists,
} from "./../utils/common";
import { COUNTRY_QUERY_PARAM, URL_SEARCH_PARAMS } from "./../constants";
const fallBackLang = {
  lang: "en",
  location: "/static/mock-data/localization/translation/en.json",
};

const params: ParamMap<string> = getQueryParams(URL_SEARCH_PARAMS);
const country = params[COUNTRY_QUERY_PARAM];

const fetchLanguageData = async (
  availableLocales: AvailableLocale[],
  language: string
) => {
  const localeObj = availableLocales.find(
    (locale) =>
      locale.isEnabled && locale.locale.toLowerCase() === language.toLowerCase()
  );
  let url = localeObj?.location as string;
  // console.log(`url--------->${url}`);
  // console.log(`all--------->${JSON.stringify(availableLocales)}`);
  if (url) {
    //console.log(`backendModule read found url ${url}`);
  } else {
    url = fallBackLang.location;
    //console.log(`backendModule read no url ------- ${url}`);
  }
  const data = await fetch(url);
  const json = await data.json();
  if (json.entries) {
    return json.entries;
  } else {
    return json;
  }
};

class NetworkInfoService {
  isInternetReachable(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(true);
    });
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addListener(): any {
    return false;
  }
}
/**
 * Fetches global configurations and compares them with the selected language.
 *
 * This function retrieves system settings based on the provided organization ID and language.
 * It fetches configuration properties such as generic configurations, language configurations,
 * landing page value properties, and organization map configurations. The retrieved data is
 * then cached for future use. Finally, it fetches language data and executes the provided callback
 * with the fetched data.
 *
 * @param {ReadCallback} callback - The callback function to execute with the fetched data.
 * @param {string} language - The language code to fetch configurations for.
 * @param {string} orgId - The organization ID to fetch configurations for.
 */
function fetchGlobalConfigurations(
  callback: ReadCallback,
  language: string,
  orgId: string
) {
  const systemSettingsKeys = `${configurationKeys.genericConfigurationProperties},${configurationKeys.languageConfigurationProperties},${configurationKeys.landingPageValueProps},${configurationKeys.organizationMapConfigurationProperties}`;
  getSystemSettings({
    category: `${configurationKeys.globalConfigurationCategory}`,
    organization: orgId,
    key: systemSettingsKeys,
    fromCacheIfAvailable: false,
    isPublic: true,
  }).then((globalConfigurationResponseData) => {
    const genericData = globalConfigurationResponseData?.find(
      (config: GlobalConfigurationsProperties) =>
        config.key === configurationKeys.genericConfigurationProperties
    );
    const languagesData = globalConfigurationResponseData?.find(
      (config: GlobalConfigurationsProperties) =>
        config.key === configurationKeys.languageConfigurationProperties
    );
    const languages = languagesData?.value?.languages;
    makeTheNavigatorLangAsDefaultIfExists(languages);
    cacheLayer.setToCache(
      configurationKeys.configurationUpdateTimeStamp,
      genericData.value.configurationUpdateTimeStamp
    );
    cacheLayer.setToCache(configurationKeys.availableLocales, languages);
    fetchLanguageData(languages, language).then((data: AvailableLocale) => {
      callback(null, data);
    });
  });
}
// custom backend module that allow us to use the api client and have more control on http requests
const backendModule: BackendModule = {
  type: "backend",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init(): void {},
  read(language: string, namespace: string, callback: ReadCallback): void {
    const { AuthConfig } = API_BASE_CONFIGS;
    EnvironmentManagerCore.setEnvironment(
      AuthConfig,
      new StorageManager(AuthConfig, null)
    );

    // This is needed by core-services to control clientId field in AS AccessToken call
    NetworkManager.configure(new NetworkInfoService(), () => {
      // Redirect to login if any of the API returns 401 error.
      // Excluding the request from the authentication pages.
      if (!window.location.pathname.includes("/authentication/")) {
        removeAuthenticationCookies();
        const countryParam = sessionStorage.getItem(COUNTRY_QUERY_PARAM) ? `?${COUNTRY_QUERY_PARAM}=${sessionStorage.getItem(COUNTRY_QUERY_PARAM)}` : "";
        window.location.pathname = `${AppConstants.APP_LOGIN_ROUTE}${countryParam}`;
      }
    });

    InjectionManagerCore.setAccessManagementCommunicator(
      new InjectionManager()
    );
    const availableLocaleFromCache = cacheLayer.getFromCache(
      configurationKeys.availableLocales
    );
    if (availableLocaleFromCache) {
      fetchLanguageData(availableLocaleFromCache, language).then(
        (data: AvailableLocale) => {
          callback(null, data);
        }
      );
    } else {
      if (country) {
        sessionStorage.setItem(COUNTRY_QUERY_PARAM, country);
        getSystemSettings({
          category: `${configurationKeys.globalConfigurationCategory}`,
          key: configurationKeys.organizationMapConfigurationProperties,
          fromCacheIfAvailable: false,
          isPublic: true,
        }).then((globalConfigurationResponseData) => {
          const currentOrganizationId = globalConfigurationResponseData?.value[country].organizationId;
          cacheLayer.setToCache("currentOrganizationId",currentOrganizationId);
          fetchGlobalConfigurations(
            callback,
            language,
            country
              ? currentOrganizationId
              : ""
          );
        });
      } else {
        fetchGlobalConfigurations(callback, language, "");
      }
    }
  },
};

let _inited = false;

export const initLocalization = async () => {
  if (!_inited) {
    // NOTE:
    // Cannot set default locale id here as this runs before an API call is executed
    // thus we cannot read the lcid value by the hostname lookup we receive from the Global info.json in the GlobalStore loadInfo method

    // init i18n
    const options: InitOptions = {
      lng: "en",
      fallbackLng: "en",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        /**
         * Add interpolation format method to customize the formatting
         */
        format: (value, format) => {
          if (format === "uppercase") {
            return value.toUpperCase();
          }
          return value;
        },
      },
      load: "currentOnly",
      preload: false,
    };
    await i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .use(backendModule)
      .init(options);

    _inited = true;
  }
  return _inited;
};
