import { URL_SEARCH_PARAMS } from "./../constants";

const environmentUtils = Object.freeze({
  get baseUrl(): string {
    const baseUrl = (window.teamCareBuildConfigs?.baseUrl || "").trim();
    if (baseUrl.length > 0) {
      return baseUrl;
    }
    return "";
  },
  get appAPIProxy(): string {
    const appAPIProxy = (window.teamCareBuildConfigs?.apiProxy || "").trim();
    if (appAPIProxy.length > 0 && appAPIProxy.indexOf("${") === -1) {
      return appAPIProxy;
    }
    return "api-proxies";
  },
  get nameSpace(): string {
    const appNamespace = (window.teamCareBuildConfigs?.nameSpace || "").trim();
    if (appNamespace.length > 0 && appNamespace.indexOf("${") === -1) {
      return appNamespace;
    }
    return "";
  },
  get apiKey(): string {
    const apiKey = (window.teamCareBuildConfigs?.apiKey || "").trim();
    if (apiKey.length > 0 && apiKey.indexOf("${") === -1) {
      return apiKey;
    }
    return "api-key";
  },
  get tenantName(): string {
    const tenantName = (window.teamCareBuildConfigs?.tenantName || "").trim();
    if (tenantName.length > 0 && tenantName.indexOf("${") === -1) {
      return tenantName;
    }
    return "tenantName";
  },
  get applicationName(): string {
    const applicationName = (
      window.teamCareBuildConfigs?.applicationName || ""
    ).trim();
    const params = new URLSearchParams(URL_SEARCH_PARAMS);
    const name = params.get("portal");
    if (name) {
      return name;
    }
    if (applicationName.length > 0 && applicationName.indexOf("${") === -1) {
      return applicationName;
    }
    return "applicationName";
  },
});

export const getEnvironmentVariables = () => {
  return environmentUtils;
};
