import { AuthenticationService } from "@brightinsight/core-services";
import { cookieService } from "@brightinsight/bi-web-core";

export const removeAuthenticationCookies = () => {
  cookieService.removeCookies([
    "access_token",
    "refresh_token",
    "scope",
    "userId",
    "organizationId",
    "id_token",
    "token_type",
    "expires_in",
    "nonce",
    "expiration_time",
    "authIdToken",
    "redirectUri",
  ]);
};
export const renewAccessTokenRefreshToken = async () => {
  return AuthenticationService.getAccessTokenRefreshToken({
    data: { grantType: "refresh_token" },
  });
};
