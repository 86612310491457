import React from "react";
import { Loading as LoadingComponent } from "@brightinsight/bi-web-core";
import LoadingIcon from "@brightinsight/bi-web-core/assets/LoadingIcon.svg";

const Loading = () => {
  return (
    <LoadingComponent
      label={"Loading..."}
      iconSource={LoadingIcon}
      id={"initial-page-loading"}
      show={true}
    />
  );
};

export default Loading;
