import React, { ReactNode, useEffect } from "react";
import Cookies from "js-cookie";
import i18next from "i18next";
import { getSystemSettings } from "@brightinsight/bi-web-common-modules";
import {
  Loading,
  cacheLayer,
  useAppConfigContext,
  AppConfigContextType,
  ParamMap,
} from "@brightinsight/bi-web-core";
import { EnvironmentManager, InternalStorageKeys } from '@brightinsight/core-services';
import LoadingIcon from "@brightinsight/bi-web-core/assets/LoadingIcon.svg";
import { SESSION_LABEL, URL_SEARCH_PARAMS, COUNTRY_QUERY_PARAM } from "./../../../../constants";
import {
  AvailableLocale,
  GlobalConfigurationsPropertiesResponse,
  WebFileValue,
  WebFileConfigData,
  GlobalFileConfigurationsPropertiesResponse,
} from "../../../../common/@types/common.interface";

import { configurationKeys } from "../../../../common/config";
import { AppConstants } from "./../../../../constants/AppConstants";
import { getQueryParams } from "./../../../../utils/common";

type Props = {
  children: ReactNode;
};

const AppContainer = ({ children }: Props) => {
  const { state, dispatch } = useAppConfigContext<AppConfigContextType>();

  const { globalConfigsLoaded, renewingSession = false } = state.data;
  const isLoggedIn = !!Cookies.get(AppConstants.ACCESS_TOKEN);
  const params: ParamMap<string> = getQueryParams(URL_SEARCH_PARAMS);
  const country = params[COUNTRY_QUERY_PARAM] || sessionStorage.getItem(COUNTRY_QUERY_PARAM)

  useEffect(() => {
    async function fetchGlobalConfigs() {
      if (!globalConfigsLoaded) {
        const systemSettingsKeys = `${configurationKeys.genericConfigurationProperties},${configurationKeys.languageConfigurationProperties},${configurationKeys.landingPageValueProps},${configurationKeys.organizationMapConfigurationProperties}`;

        // Fetch global configuration settings
        const globalConfigurationResponseData = await getSystemSettings({
          category: `${configurationKeys.globalConfigurationCategory}`,
          key: systemSettingsKeys,
          fromCacheIfAvailable: true,
          isPublic: true,
        });
        // Extract generic configuration properties
        const genericConfigurationsProperties =
          globalConfigurationResponseData?.find(
            (config: GlobalConfigurationsPropertiesResponse) =>
              config.key === configurationKeys.genericConfigurationProperties
          );

        // Extract language configuration properties
        const organizationConfigurationsProperties =
          globalConfigurationResponseData?.find(
            (config: GlobalConfigurationsPropertiesResponse) =>
              config.key ===
              configurationKeys.organizationMapConfigurationProperties
          );
        // Extract value-props configuration properties
        const valuePropsConfigurationsProperties =
          globalConfigurationResponseData?.find(
            (config: GlobalConfigurationsPropertiesResponse) =>
              config.key === configurationKeys.landingPageValueProps
          );

        const { organizationId, supportPhone, backgroundColor } =
          organizationConfigurationsProperties?.value[country || "default"] ||
          {};
        const organizationConfigs = {
          organizationId,
          supportPhone,
          backgroundColor,
        };
        EnvironmentManager.storageService.setValue(InternalStorageKeys.ORGANIZATION_ID, organizationId);
        cacheLayer.setToCache(
          "genericConfigurationsProperties",
          genericConfigurationsProperties
        );
        // Fetch web file configuration settings
        const webFileConfigurationResponseData = await getSystemSettings({
          category: `${configurationKeys.webFileConfigurationCategory}`,
          fromCacheIfAvailable: true,
          isPublic: true,
        });

        // Process web file configuration data
        const webFileConfig: WebFileConfigData = {};
        webFileConfigurationResponseData?.forEach(
          (config: GlobalFileConfigurationsPropertiesResponse) => {
            if (
              config.categories[0].name ===
              configurationKeys.webFileConfigurationCategory
            ) {
              webFileConfig[config.key] = config.value as WebFileValue;
            }
          }
        );

        // Retrieve available languages from cache
        const availableLanguages = cacheLayer.getFromCache(
          configurationKeys.availableLocales
        );
        let defaultLanguage;
        if (availableLanguages) {
          if (availableLanguages?.length > 0) {
            // Set default language if available
            defaultLanguage = availableLanguages.find(
              (language: AvailableLocale) => language.isDefault
            ) as AvailableLocale;
            i18next.changeLanguage(defaultLanguage.locale);
          }
        }
        dispatch({
          type: "setAppConfigState",
          payload: {
            globalConfigsLoaded: true,
            isLoggedIn,
            selectedLanguage: defaultLanguage,
            availableLanguages: availableLanguages,
            genericConfigurations: genericConfigurationsProperties?.value,
            webFileConfig,
            appContext: genericConfigurationsProperties?.value.appContext,
            languageConfigs: organizationConfigs, //::TODO:: should be renamed to org config
            landingPageValueProps:
              valuePropsConfigurationsProperties?.value.value,
          },
        });
      }
    }
    fetchGlobalConfigs();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Loading
        show={renewingSession}
        label={SESSION_LABEL.RENEW}
        iconSource={LoadingIcon}
      />
      {globalConfigsLoaded && children}
    </div>
  );
};

export default AppContainer;
