import Cookies from "js-cookie";
import { ParamMap } from "@brightinsight/bi-web-core";
import {
  COUNTRY_QUERY_PARAM,
  GCP_ASSETS_PATH,
  GCP_PUBLIC_FOLDER,
  URL_SEARCH_PARAMS,
} from "./../constants";
import { AvailableLocale } from "../common/@types/common.interface";
import { AppConstants } from "../constants/AppConstants";


//TODO its a temporary placeholder. This should be moved to bi-web-core
export function groupByKey(array: [], key: string) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: ((hash[obj[key]] || []) as string).concat(obj),
    });
  }, {});
}
// eslint-disable-next-line
export const handleImports: any = async (module: string) => {
  switch (module) {
    case "@brightinsight/bi-web-common-modules":
      return await import("@brightinsight/bi-web-common-modules");
    default:
      break;
  }
};

export const browserSupportUtils = {
  getInfo: () => {
    const result = {
      browserLocales: [] as string[],
      browserLocale: "",
    };
    if (navigator) {
      result.browserLocales = navigator.languages as string[];
      result.browserLocale = navigator.language;
    }
    return result;
  },
};

export function makeTheNavigatorLangAsDefaultIfExists(
  availableLocales: AvailableLocale[]
) {
  let locales = [...availableLocales];
  const enabledLocales = availableLocales.filter((locale) => locale.isEnabled);

  //console.log("browserSupportUtils.getInfo()", browserSupportUtils.getInfo());
  const browserLocaleMatchFound = browserSupportUtils
    .getInfo()
    .browserLocales.find((localeId) =>
      enabledLocales.find(
        (locale) => localeId.toLowerCase() === locale.locale.toLowerCase()
      )
    );
  //console.log('browserLocaleMatchFound', browserLocaleMatchFound);
  if (browserLocaleMatchFound) {
    locales = enabledLocales.map((locale) => {
      const matchFound =
        browserLocaleMatchFound.toLowerCase() === locale.locale.toLowerCase();
      if (matchFound) {
        //console.log(`!!!!! browserLocaleMatchFound ${JSON.stringify(locale)}`);
        locale.isDefault = true;
      } else {
        locale.isDefault = false;
      }
      return locale;
    });
  }
  //console.log(`!!!!! final locale list ${JSON.stringify(locales)}`);
  return locales;
}

/**
 * A set of language codes that are written from right to left (RTL).
 * This includes languages such as Arabic (ar), Hebrew (he).
 */
const rtlLanguages = new Set(["ar", "he"]);

/**
 * Determines if a given locale is a right-to-left (RTL) language.
 *
 * @param {string} locale - The locale string to check, typically in the format of 'language-region' (e.g., 'en-US').
 * @returns {boolean} - Returns true if the locale is an RTL language, otherwise false.
 */
export const isRtl = (locale: string): boolean => {
  if (!locale) return false;
  const [languageCode] = locale.split("-");
  const isRtlLanguage = rtlLanguages.has(languageCode);
  window.isRTL = isRtlLanguage;
  return isRtlLanguage;
};
export const getQueryParams = (url: string): ParamMap<string> => {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params: ParamMap<string> = {};
  paramArr.map((param: string) => {
    const [key, val] = param.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
};

export const composePublicGCPURL = (): string => {
  const params: ParamMap<string> = getQueryParams(URL_SEARCH_PARAMS);
  const country = params[COUNTRY_QUERY_PARAM] || sessionStorage.getItem(COUNTRY_QUERY_PARAM)
  const tenantName = window?.teamCareBuildConfigs?.tenantName || "";
  const gcpPath = GCP_ASSETS_PATH.replace("#tenantName#", tenantName);
  return country &&country.length > 0
    ? `${gcpPath}/${GCP_PUBLIC_FOLDER}/${country}`
    : `${gcpPath}/${GCP_PUBLIC_FOLDER}`;
};
