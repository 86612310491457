import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  PreAuthLayout,
  useAppConfigContext,
  AppConfigContextType,
  ParamMap,
} from "@brightinsight/bi-web-core";
import {
  ConsentsRegistration,
  GenericHeader,
  Login,
  RegisterPage,
  ResetPasswordPage,
  VideoRoom,
} from "@brightinsight/bi-web-common-modules";

import Loading from "../pages/loading/Loading";
import InitializeApplnRoutes from "./InitializeApplnRoutes";
import { GetLoggedInUserResponse } from "@brightinsight/core-services";
import { COUNTRY_QUERY_PARAM, PAGE_LAYOUT, URL_SEARCH_PARAMS } from "./../constants/common";
import { AppConstants } from "./../constants/AppConstants";
import { composePublicGCPURL } from "./../utils/common";

const userName = "User_" + Math.random().toString(36).substr(2, 5);
const userId = Math.random().toString(36).substr(2, 5);

const getQueryParams = (url: string): ParamMap<string> => {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params: ParamMap<string> = {};
  paramArr.map((param: string) => {
    const [key, val] = param.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
};

const params: ParamMap<string> = getQueryParams(URL_SEARCH_PARAMS);
const meetingId = (params && params["meetingId"]) || "";

const loginBackground = `${composePublicGCPURL()}/background.png`;
const logo = `${composePublicGCPURL()}/logo.png`;

// MainRoutes component definition
export const MainRoutes = () => {
  // Extract state and dispatch from the module context
  const { state, dispatch } = useAppConfigContext<AppConfigContextType>();
  const { isLoggedIn, languageConfigs } = state.data;
  
  const handleLoginSuccess = (userDetails: GetLoggedInUserResponse) => {
    // Dispatch action to set user profile info on login success
    dispatch({
      type: "setUserProfileInfo",
      payload: { userDetails, languageConfigs },
    });
    dispatch({
      type: "setAppConfigState",
      payload: { isLoggedIn: true },
    });
  };
  
  const countryParam = sessionStorage.getItem(COUNTRY_QUERY_PARAM) ? `?${COUNTRY_QUERY_PARAM}=${sessionStorage.getItem(COUNTRY_QUERY_PARAM)}` : "";

  return (
    // Suspense component to show loading fallback
    <Suspense fallback={<Loading />}>
      {isLoggedIn ? (
        // If the user is logged in, render InitializeApplnRoutes
        <InitializeApplnRoutes />
      ) : (
        // If the user is not logged in, render the login routes
        <Routes>
          <Route
            path={AppConstants.APP_LOGIN_ROUTE}
            element={
              // PreAuthLayout component with Login and GenericHeader
              <PreAuthLayout
                backgroundColor={languageConfigs?.backgroundColor ?? ""}
                loginBackground={loginBackground}
                contentComponent={
                  <Login
                    triggerLoginSuccess={handleLoginSuccess}
                  />
                }
                headerComponent={
                  <GenericHeader
                    layout={PAGE_LAYOUT.PRE_AUTH_LAYOUT}
                    portalContext={null}
                    logo={logo}
                  />
                }
              />
            }
          />
          <Route
            path={AppConstants.APP_LOGIN_REGISTER}
            element={
              <PreAuthLayout
                backgroundColor={languageConfigs?.backgroundColor ?? ""}
                loginBackground={loginBackground}
                contentComponent={<RegisterPage triggerLoginSuccess={handleLoginSuccess}/>}
                headerComponent={
                  <GenericHeader
                    layout={PAGE_LAYOUT.PRE_AUTH_LAYOUT}
                    portalContext={null}
                    logo={logo}
                  />
                }
              />
            }
          />
          <Route
            path={AppConstants.APP_RESET_PASSWORD}
            element={
              <PreAuthLayout
                backgroundColor={languageConfigs?.backgroundColor ?? ""}
                loginBackground={loginBackground}
                contentComponent={<ResetPasswordPage triggerLoginSuccess={handleLoginSuccess}/>}
                headerComponent={
                  <GenericHeader
                    layout={PAGE_LAYOUT.PRE_AUTH_LAYOUT}
                    portalContext={null}
                    logo={logo}
                  />
                }
              />
            }
          />
          <Route
            path={AppConstants.PATIENT_CONSENTS}
            element={<ConsentsRegistration />}
          />
          <Route
            path={"/meet-room"}
            element={
              <VideoRoom
                userId={userId}
                userName={userName}
                meetingId={meetingId}
                type="external"
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to={`${AppConstants.APP_LOGIN_ROUTE}${countryParam}`} />}
          />
        </Routes>
      )}
    </Suspense>
  );
};
