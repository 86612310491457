import React, { useEffect, useState, useCallback } from "react";
import { t } from "i18next";
import { getEnvironmentVariables } from "../utils/environmentVariables";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import {
  TypeAny,
  useAppConfigContext,
  AppConfigContextType,
  GlobalConfigurationValue,
  NavigationBlocker,
  cacheLayer,
  NotificationService,
  NotificationContainer,
} from "@brightinsight/bi-web-core";
import {
  featureList,
  getDefaultModuleroute,
  getAppContextRoute,
} from "@brightinsight/bi-web-common-modules";
import { NavigationBar, PageLayout, Loading } from "@brightinsight/bi-web-core";
import { GenericHeader } from "@brightinsight/bi-web-common-modules";
import AuthContainer from "./../modules/common/components/auth-container/AuthContainer";
import LoadingIcon from "@brightinsight/bi-web-core/assets/LoadingIcon.svg";
import { getRoutes } from "./RouteUtils";
import { CACHE_LAYER_KEYS, PAGE_LAYOUT } from "./../constants/common";
import { FeatureObj, MenuItem, RouteObj } from "./@types/types";
import { composePublicGCPURL } from "./../utils/common";

const InitializeApplnRoutes = () => {
  // Get state and dispatch from the module context
  const { state, dispatch } = useAppConfigContext<AppConfigContextType>();

  // Destructure necessary data from state
  const { appContext = "", userDetails, genericConfigurations } = state.data;

  // Extract roles from userDetails
  const { roles } = userDetails;

  // Get the first role details
  const [roleDetails] = roles;

  // Extract roleId from roleDetails
  const { roleId } = roleDetails;

  // Get application name from environment variables
  const { applicationName } = getEnvironmentVariables();

  // Initialize navigate function from react-router-dom
  const navigate = useNavigate();

  // State to hold application routes
  const [applnRoutes, setApplnRoutes] = useState<RouteObj[]>([]);

  // State to hold application menu items
  const [applnMenu, setApplnMenus] = useState<MenuItem[]>([]);

  // State to control portal context visibility
  const [portalContext, setPortalContext] = useState<MenuItem[] | null>(null);

  // State to hold the default route
  const [defaultRoute, setDefaultRoute] = useState<string>("");
  const logo = `${composePublicGCPURL()}/logo.png`;
  // Function to get application features
  const getApplnFeatures = async () => {
    // Check if application features are empty
    //::TODO:: Commenting for time being - for the release
    //if (applicationFeatures && !Object.keys(applicationFeatures).length) {
    // Fetch feature list if empty
    const featureObj: FeatureObj = await featureList(
      roleId,
      appContext,
      applicationName,
      [],
      genericConfigurations?.appContextOptions || []
    );

    // Dispatch action to set application features in state
    // TODO: Can be moved to cache layer
    dispatch({
      type: "setAppConfigState",
      payload: {
        applicationFeatures: featureObj.applicationFeaturesModules,
      },
    });
    // } else {
    //   // Get application features from existing state
    //   featureObj = getApplicationFeatures(
    //     applicationFeatures,
    //     applicationName,
    //     appContext,
    //     genericConfigurations?.appContextOptions || []
    //   );
    // }

    // Destructure necessary data from featureObj
    const { portalContextModules = [], portalContext = null } = featureObj;

    // Get routes and menus based on modules and application features
    const { allAuthRoutes, allMenus } = await getRoutes(portalContextModules);
    setDefaultRoute(allMenus?.[0]?.path || "");
    if (window.location.pathname.includes("/authentication/")) {
      sessionStorage.setItem("selectedPath",allMenus?.[0]?.path);
    } else {
      sessionStorage.setItem("selectedPath",location.pathname);
    }

    // Set portal context visibility
    setPortalContext(portalContext);

    // Set application routes
    if (allAuthRoutes) {
      setApplnRoutes(allAuthRoutes);
      checkCacheAndNotify();
    }

    // Set application menus
    setApplnMenus(allMenus);
  };

  // useEffect to fetch application features on appContext change
  useEffect(() => {
    (async () => {
      await getApplnFeatures();
      // const appContextRoute = getAppContextRoute(state.data);
      // setDefaultRoute(appContextRoute);
    })();
    // eslint-disable-next-line
  }, [appContext]);

  // Needed for routing based on post login onboarding step and context switch
  // Commeting as of now as its not required in MPP.
  // useEffect(() => {
  //   (async () => {
  //     if (
  //       Object.keys(genericConfigurations as GlobalConfigurationValue).length
  //     ) {
  //       // Get the default module route
  //       const defaultRoute = await getDefaultModuleroute(state.data);
  //       setDefaultRoute(defaultRoute);
  //     }
  //   })();
  //   // eslint-disable-next-line
  // }, [genericConfigurations]);

  const checkCacheAndNotify = async () => {
    const accountCreated = await cacheLayer.getFromCache(CACHE_LAYER_KEYS.ACCOUNT_CREATED);
    const passwordReset = await cacheLayer.getFromCache(CACHE_LAYER_KEYS.PASSWORD_RESET);
    if (accountCreated) {
      NotificationService.success({ message: t('register.userOnboardingSuccess'), timeOut: 5000 });
      cacheLayer.setToCache(CACHE_LAYER_KEYS.ACCOUNT_CREATED, false);
    }else if(passwordReset){
      NotificationService.success({ message: t('resetPassword.resetPasswordSuccess'), timeOut: 5000 });
      cacheLayer.setToCache(CACHE_LAYER_KEYS.PASSWORD_RESET, false);
    }
  };

  // Handle menu selection
  const handleMenuSelection = useCallback(
    (path: TypeAny) => {
      if (path !== "" && path !== location.pathname) {
        navigate(path);
      }
    },
    [navigate]
  );

  return (
    <>
      {!applnRoutes.length && (
        <Loading
          show={applnRoutes.length === 0}
          label={t("loading")}
          iconSource={LoadingIcon}
        />
      )}
      {applnRoutes.length && (
        <AuthContainer>
          <NavigationBlocker
            confirmPopupCancelKey={t("cancel")}
            confirmPopupConfirmKey={t("ok")}
            confirmPopupHeader={t("leavePage")}
            confirmPopupText={t("leavePageContent")}
          />
          <PageLayout
            contentComponent={
              <Routes>
                {applnRoutes.map((route: RouteObj, index: number) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.component}
                    />
                  );
                })}
                <Route
                  path="*"
                  element={
                    // Redirect to appropriate route based on login status
                    <Navigate to={defaultRoute} />
                  }
                />
              </Routes>
            }
            headerComponent={
              <GenericHeader
                logo={logo}
                key={"static"}
                portalContext={portalContext}
                layout={PAGE_LAYOUT.POST_AUTH_LAYOUT}
              />
            }
            navigationComponent={
              <NavigationBar
                menus={applnMenu}
                onMenuSelection={handleMenuSelection}
                selectedPath={sessionStorage.getItem("selectedPath") || ""}
                showLabelsWhenCollapsed={false}
              />
            }
          />
        </AuthContainer>
      )}
    </>
  );
};

export default InitializeApplnRoutes;
