import { getEnvironmentVariables } from "../utils/environmentVariables";
import { ClientEnv } from "@brightinsight/core-services";

const { baseUrl, apiKey, tenantName, applicationName, nameSpace, appAPIProxy } =
  getEnvironmentVariables();
const API_BASE_CONFIGS = {
  AuthConfig: {
    baseUrl,
    namespace: `${appAPIProxy}${nameSpace}`,
    apiKey,
    tokenType: "Bearer",
    redirectUri: "http://localhost",
    clientEnv: ClientEnv.WEB,
    clientId: "oidc_pkce",
  },
  tenantName,
  applicationName,
};

export default API_BASE_CONFIGS;

export const configurationKeys = {
  globalConfigurationCategory: "web.global",
  globalLocaleConfigCategory: "global.config.locale",
  webFileConfigurationCategory: "WebFileConfig",
  webStyleConfigurationCategory: "WebStyleConfig",
  webStyleConfigurationKey: "web.style.global",
  languageConfigurationProperties: "web.global.languages",
  modulesConfigurationProperties: "web.global.modules",
  genericConfigurationProperties: `web.global.generic`,
  commonMenuConfigurationProperties: "web.global.menu",
  configurationUpdateTimeStamp: "configurationUpdateTimeStamp",
  availableLocales: "availableLocales",
  landingPageValueProps: "web.global.landingpage.valueprops",
  organizationMapConfigurationProperties: "web.global.organization.map",
};
