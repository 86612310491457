import React, { useEffect } from "react";
import { useTheme } from "react-jss";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorBoundary from "./modules/common/components/error-boundary/ErrorBoundary";
import { MainRoutes } from "./routes/MainRoutes";
import AppContainer from "./modules/common/components/app-container/AppContainer";
import {
  configureTheme,
  PubSubProvider,
  TypeAny,
} from "@brightinsight/bi-web-core";

import GlobalAppConfigProvider from "./services/AppConfigProvider";

const router = createBrowserRouter([
  {
    path: "/*",
    element: (
      <PubSubProvider>
        <GlobalAppConfigProvider>
          <ErrorBoundary>
            <AppContainer>
              <MainRoutes />
            </AppContainer>
          </ErrorBoundary>
        </GlobalAppConfigProvider>
      </PubSubProvider>
    ),
  },
]);

/**
 * The App component serves as the main entry point for the application.
 * It utilizes the `useTheme` hook to access the current theme and applies
 * it using the `configureTheme` function. Additionally, it manages the
 * dynamic loading of a font stylesheet based on the theme's `fontLink` property.
 *
 * The component is wrapped with a `RouterProvider` to handle routing within
 * the application.
 */
export const App = () => {
  // Access the current theme using the useTheme hook
  const { theme } = useTheme() as { theme: TypeAny };

  // Apply the current theme configuration
  configureTheme();

  useEffect(() => {
    // Dynamically load a font stylesheet if the theme specifies a font link
    if (theme?.fontLink) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = theme.fontLink;
      document.head.appendChild(link);

      // Cleanup function to remove the font stylesheet when the component unmounts
      return () => {
        document.head.removeChild(link);
      };
    }
  }, [theme]);

  // Render the application with routing capabilities
  return <RouterProvider router={router} />;
};
