export const MODULE_PATH_PROPERTY = "path";
export const TRANSLATION = "translations";
export const EN_US = "en-US";
export const PAGE_LAYOUT = {
  PRE_AUTH_LAYOUT: "pre-auth-layout",
  POST_AUTH_LAYOUT: "post-auth-layout",
};
export const SESSION_LABEL = {
  RENEW: "Renewing Session",
};
export const IDLE_TIMEOUT_IN_MILLISECONDS = 799000;
export const METADATA_PAGE_SIZE = 7;
export const METADATA_OBJECT_NAMES: string[] = ["PermissionSet"];
export const DEFAULT_METADATA_OBJECTS_TO_BE_LOADED = ["PermissionSet"];
export const METADATA_APPLICATION_NAME = "bi-dms";
export const MAX_SIZE = 1000;

// Permissions and access are not checked for the default features.
export const DEFAULT_APPLICATION_FEATURES = [
  "Authentication",
  "Profile",
  "Onboarding",
];

export const APPLICATION_FEATURES = {
  KEY: "applicationFeatures",
  ACTIONS: {
    VIEW: "view",
    ADD: "add",
    UPDATE: "update",
    DELETE: "delete",
  },
};

export const MENUS = {
  VIEW_PATIENTS: "view-patients",
  VIEW_MESSAGES: "view-messages",
  VIEW_TEAMS: "view-teams",
  VIEW_HELP: "view-help",
};

export const URL_SEARCH_PARAMS = window.location.search;
export const GCP_ASSETS_PATH =
  "https://storage.googleapis.com/#tenantName#-asset-management-service";
export const GCP_PUBLIC_FOLDER = "public/web";

export const CACHE_LAYER_KEYS = {
  ACCOUNT_CREATED: 'accountCreated',
  PASSWORD_RESET: 'passwordReset',
}
export const COUNTRY_QUERY_PARAM = "country";
