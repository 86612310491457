import { MetaDataParams } from "common/@types/common.interface";
import { METADATA_PAGE_SIZE } from "../constants";
import {
  MetaDataService,
  CreateMetaDataInstanceParameters,
  UpdateMetaDataInstanceParameters,
  GetMetaDataObjectsParameters,
  GetMetaDataInstancesParameters,
  APIResult,
  CoreResponse,
  GetMetadataApplicationListResponse,
} from "@brightinsight/core-services";

export const metaDataService = {
  getMetaDataObjectsById: async (params: MetaDataParams) => {
    const getMetaDataParams: GetMetaDataObjectsParameters = {
      data: {
        id: params.id,
        page: params?.pagination?.page || 1,
        size: params?.pagination?.size || METADATA_PAGE_SIZE,
        sort: [
          ...(params?.sorting?.fields || []),
          params?.sorting?.order || "desc",
        ],
      },
    };
    const response = await MetaDataService.getMetaDataObjectsById(
      getMetaDataParams
    );
    return response.apiResponse;
  },
  getMetaDataInstancesById: async (params: MetaDataParams) => {
    const getMetaDataParams: GetMetaDataInstancesParameters = {
      data: {
        id: params.id,
        size: "100",
        sort: "updatedOn,desc",
      } as unknown as GetMetaDataInstancesParameters["data"],
    };
    const response = await MetaDataService.getMetaDataInstancesById(
      getMetaDataParams
    );
    return response.apiResponse;
  },
  createMetaDataInstance: async (params: CreateMetaDataInstanceParameters) => {
    const response = await MetaDataService.createMetaDataInstanceById(params);
    return response;
  },
  updateMetaDataInstance: async (params: UpdateMetaDataInstanceParameters) => {
    const response = await MetaDataService.updateMetaDataInstanceById(params);
    return response;
  },
  getApplication: async (searchKey: string) => {
    const response: APIResult<GetMetadataApplicationListResponse> =
      await MetaDataService.getMetadataApplicationList({
        data: { name: searchKey },
      });
    const responseData = (
      response.apiResponse as CoreResponse<GetMetadataApplicationListResponse>
    ).data?.data;
    const appId = (
      (responseData || []).find(({ name }) => name === searchKey) || {}
    ).id;
    return appId;
  },
};
