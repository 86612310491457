import { KeyValueObject, TypeAny } from "@brightinsight/bi-web-core";
import {
  CoreResponse,
  GetMetaDataObjectsResponse,
} from "@brightinsight/core-services";
import { getSystemSettings } from "@brightinsight/bi-web-common-modules";
import { MetaDataObject } from "@brightinsight/core-services/dist/services/interfaces/MetaDataInterfaces";

import { metaDataService } from "./../services/metaDataService";
import { METADATA_APPLICATION_NAME } from "./../constants";
import { configurationKeys } from "./../common/config";

interface PostAuthProps {
  selectedLanguage: string;
  updateConfigs: ([key]: TypeAny) => void;
}

export async function handlePostAuthentions({
  selectedLanguage,
  updateConfigs,
}: PostAuthProps) {
  // Function to get localization config
  const getLocalizationConfig = async () => {
    const globalConfigurationResponseData = await getSystemSettings({
      category: configurationKeys.globalLocaleConfigCategory,
      key: `${configurationKeys.globalLocaleConfigCategory}.${selectedLanguage}`,
      fromCacheIfAvailable: true,
    });
    const selectedLocaleConfig: KeyValueObject<string> = {};
    if (globalConfigurationResponseData?.value) {
      globalConfigurationResponseData.value.forEach(
        (config: { name: string; value: string }) => {
          selectedLocaleConfig[config.name] = config.value;
        }
      );
      //selectedLocaleConfig = selectedLocaleConfig;
    }
    return selectedLocaleConfig;
  };
  // Function to get application metadata
  const getApplicationMetaData = async () => {
    //console.log('in getApplicationMetaData');
    const appId = await metaDataService.getApplication(
      METADATA_APPLICATION_NAME
    );

    if (appId) {
      const id = appId;
      const objectsResponse = await metaDataService.getMetaDataObjectsById({
        id,
        pagination: { page: 1, size: 100 },
        sorting: {
          fields: ["createdOn"],
          order: "asc",
        },
      });

      const responseData = (
        objectsResponse as CoreResponse<GetMetaDataObjectsResponse>
      )?.data;
      const metaObjectSchemas = responseData?.data ?? [];
      const metaDataObjects: KeyValueObject<string> = {};

      // Map metadata object names to their IDs
      metaObjectSchemas.forEach((object: MetaDataObject) => {
        metaDataObjects[object.name] = object.id;
      });

      return metaDataObjects;
    }
  };

  // Fetch metadata objects and update configurations
  const metaDataObjects = await getApplicationMetaData();
  const selectedLocaleConfig = await getLocalizationConfig();
  updateConfigs({
    type: "setAppConfigState",
    payload: {
      metaDataObjects,
      isLoggedIn: true,
      selectedLocaleConfig,
    },
  });
}
