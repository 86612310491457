import React, { ReactNode, useEffect, useState } from "react";
import { LoginExpireModal } from "@brightinsight/bi-web-common-modules";
import { useIdleTimer } from "react-idle-timer";
import {
  useAppConfigContext,
  AppConfigContextType,
  GlobalConfigurationValue,
  TypeAny,
} from "@brightinsight/bi-web-core";
import { IDLE_TIMEOUT_IN_MILLISECONDS } from "../../../../constants";
import { handlePostAuthentions } from "./../../../../utils/postAuth";

type Props = {
  children: ReactNode;
};
const AuthContainer = ({ children }: Props) => {
  const {
    state: { data: configurations },
    dispatch,
  } = useAppConfigContext<AppConfigContextType>();
  const { userId, roles, organization } = configurations.userDetails;
  const { genericConfigurations } = configurations;
  const { session } = genericConfigurations as GlobalConfigurationValue;
  const { waringInactivityTimeOutInMS = IDLE_TIMEOUT_IN_MILLISECONDS } =
    session;

  const [renewModalActive, setRenewModalActive] = useState(false);
  const [initialMetaDataLoaded, setInitialMetaDatLoaded] = useState(false);

  const onActive = () => {
    setRenewModalActive(true);
  };

  const onIdle = () => {
    setRenewModalActive(true);
  };

  const { activate } = useIdleTimer({
    onActive,
    onIdle,
    timeout: waringInactivityTimeOutInMS,
    events: ["mousemove", "keydown"],
  });

  const initializeTimer = () => {
    setRenewModalActive(false);
    activate();
  };

  useEffect(() => {
    const postAuth = async () => {
      await handlePostAuthentions({
        selectedLanguage: configurations?.selectedLanguage?.locale || "",
        updateConfigs: dispatch,
      });
    };

    // Set the user information for Datadog RUM
    const setDatadogRumUser = () => {
      const user = {
        id: userId,
        organizations: organization.map(
          ({ organizationId }: TypeAny) => organizationId
        ),
        tags: "",
        roles: roles.map(({ roleId }: TypeAny) => roleId),
      };
      window.DD_RUM.setUser(user);
    };

    const initialize = async () => {
      await postAuth();
      setDatadogRumUser();
      setInitialMetaDatLoaded(true);
    };

    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {initialMetaDataLoaded && children}
      {renewModalActive && (
        <LoginExpireModal initializeTimer={initializeTimer} />
      )}
    </div>
  );
};
export default AuthContainer;
