import { DefaultPrivacyLevel, datadogRum } from "@datadog/browser-rum";
import { TypeAny } from "@brightinsight/bi-web-core";

const getConfigValue = (
  key: keyof typeof window.datadogRum,
  defaultValue: string | boolean | number,
  transform: (val: string) => TypeAny = (val) => val
) => {
  const value = (window.datadogRum?.[key] || "").trim();
  if (value.length > 0 && value.indexOf("${") === -1) {
    return transform(value);
  }
  return defaultValue;
};

const datadogRumConfiguration = Object.freeze({
  get applicationId(): string {
    return getConfigValue("applicationId", "application-id");
  },
  get clientToken(): string {
    return getConfigValue("clientToken", "client-token");
  },
  get site(): string {
    return getConfigValue("site", "site");
  },
  get service(): string {
    return getConfigValue("service", "service");
  },
  get env(): string {
    return getConfigValue("env", "env");
  },
  get version(): string {
    return getConfigValue("version", "version");
  },
  get sessionSampleRate(): number {
    return getConfigValue("sessionSampleRate", 0, Number);
  },
  get sessionReplaySampleRate(): number {
    return getConfigValue("sessionReplaySampleRate", 0, Number);
  },
  get trackResources(): boolean {
    return getConfigValue(
      "trackResources",
      false,
      (val) => val.toLowerCase() === "true"
    );
  },
  get trackLongTasks(): boolean {
    return getConfigValue(
      "trackLongTasks",
      false,
      (val) => val.toLowerCase() === "true"
    );
  },
  get trackUserInteractions(): boolean {
    return getConfigValue(
      "trackUserInteractions",
      false,
      (val) => val.toLowerCase() === "true"
    );
  },
  get defaultPrivacyLevel(): DefaultPrivacyLevel {
    return getConfigValue("defaultPrivacyLevel", "mask");
  },
});

export const initDataDogRum = (): void => {
  if (datadogRumConfiguration.applicationId !== "application-id") {
    datadogRum.init({
      ...datadogRumConfiguration,
      sessionReplaySampleRate: datadogRumConfiguration.sessionSampleRate,
      defaultPrivacyLevel:
        datadogRumConfiguration.defaultPrivacyLevel as DefaultPrivacyLevel,
      allowedTracingUrls: [
        (url) =>
          url.startsWith(
            `${window.location.protocol}//${window.location.host}`
          ),
      ],
    });
  }
  datadogRum.startSessionReplayRecording();
};
